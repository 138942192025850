import $ from 'jquery';
import '../public/js/svgxuse.min';
window.$ = $;

$(function () {
  document.querySelectorAll('input').forEach(input => {
    input.addEventListener('input', () => {
      if (input.value.length >= 0) {
        input.classList.add('focused')
        input.labels.forEach(label => {
          label.classList.add('focused')
        })
      };
    });

    input.addEventListener('focus', () => {
      input.classList.add('focused')
      input.labels.forEach(label => {
        label.classList.add('focused')
      })
    });

    input.addEventListener('blur', () => {
      if (input.value.length <= 0) {
        input.classList.remove('focused')
        input.labels.forEach(label => {
          label.classList.remove('focused')
        })
      };
    });
  });

  // tabs
  let tabs_content_width = $('.tab-items').width();
  let tab_length = $('.tab-item').length;
  let tab_width = $('.tab-item').outerWidth();
  let tabs_width = (tab_width + 5) * tab_length
  if (tabs_width > tabs_content_width) {
    $('.tab-slide-nav.next').addClass('show')
  }

  $('.tab-items').on("scroll", function () {
    let tabs_offset = $(this).offset().left;
    let tab_offset = $('.tab-item').offset().left;
    if (tab_offset < tabs_offset) {
      $('.tab-slide-nav.prev').addClass('show')
    }else {
      $('.tab-slide-nav.prev').removeClass('show')
    }
    if ((tabs_content_width + tabs_offset) - tabs_width < tab_offset) {
      $('.tab-slide-nav.next').addClass('show')
    }else {
      $('.tab-slide-nav.next').removeClass('show')
    }
  });

  $('.tab-slide-nav').on('click', function () {
    let step = tab_width + 5;
    var dir = $(this).data('direction')=="prev" ? '-=' : '+=';
    $(".tab-items").stop().animate({ scrollLeft: dir + step }, 300);
  });

  // tab-item
  $('.tab-item').on('click',function() {
    let id = $(this).find('a').attr('href');
    $(this).addClass('is-active').siblings('.tab-item').removeClass('is-active');
  });
});
